<script>
import get from 'lodash/get';
import join from 'lodash/join';

import { mapActions, mapState } from 'vuex';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import crm from '@domains/CRM/router/CRMRouterMap';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { CancelButton, GenericModalComponent, StoreNotificationComponent } from '@/components';

import { BOOKING_RULES_TYPES } from '../constants';
import { BOOKING_RULES_SCOPES } from '../store/BookingRulesModule';

export default {
  components: {
    CancelButton,
    GenericModalComponent,
    StoreNotificationComponent,
  },
  props: {
    refreshTable: {
      type: Function,
      default: () => {},
    },
    bookingRule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
    ...mapState(DOMAINS_MODEL.settings.bookingRules, {
      bookingRuleStatus: state => state[BOOKING_RULES_SCOPES.editForm].STATUS,
    }),
    isDisabledSave() {
      const areSameValue = this.bookingRule.value === this.value;
      return areSameValue || !this.value;
    },
  },
  watch: {
    bookingRule: {
      deep: true,
      handler() {
        this.value = get(this.bookingRule, 'value');
      },
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.BOOKING_RULES_SCOPES = BOOKING_RULES_SCOPES;
    this.BOOKING_RULES_TYPES = BOOKING_RULES_TYPES;
    this.crm = crm;
    this.value = this.bookingRule.value;
  },
  methods: {
    companyProfileUrl,
    get,
    join,
    ...mapActions(DOMAINS_MODEL.settings.bookingRules, [
      'patchOperatorBookingRule',
    ]),
    async saveBookingRule() {
      await this.patchOperatorBookingRule({
        operatorUuid: this.activeOperatorUuid,
        valueId: this.bookingRule.valueId,
        data: { value: this.value },
      });

      if (!this.bookingRuleStatus.ERROR) {
        this.$notify({ message: 'Booking rule successfully <span class="emobg-font-weight-semibold">edited</span>' });
        this.refreshTable();
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: `Edit ${bookingRule.key}`, isClosable: true }"
    class="BookingRulesEditModal"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.settings.bookingRules"
        :scope="BOOKING_RULES_SCOPES.editForm"
        action="editting booking rule"
      />
    </template>
    <template #body>
      <div class="emobg-font-weight-bold mb-1">
        Description
      </div>
      <div>
        {{ bookingRule.description }}
      </div>
      <div class="row">
        <div class="col-6 mt-5">
          <div class="emobg-font-weight-bold mb-1">
            Company Name
          </div>
          <div v-if="bookingRule.company">
            <a
              :href="companyProfileUrl(bookingRule.company.uuid)"
              target="_blank"
              class="emobg-link-primary emobg-body-2"
            >
              {{ bookingRule.company.name }}
            </a>
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div class="col-6 mt-5">
          <div class="emobg-font-weight-bold mb-1">
            Fleet Segment
          </div>
          <RouterLink
            v-if="bookingRule.fleetSegment"
            :to="{
              name: crm.companies.detail.fleetSegments.edit,
              params: {
                companyUuid: bookingRule.company.uuid,
                fleetSegmentUuid: bookingRule.fleetSegment.uuid,
              }
            }"
            target="_blank"
            class="emobg-link-primary emobg-body-2"
          >
            {{ bookingRule.fleetSegment.name }}
          </RouterLink>
          <div v-else>
            -
          </div>
        </div>
        <div class="col-6 mt-5">
          <div class="emobg-font-weight-bold mb-1">
            Role
          </div>
          <div>
            {{ get(bookingRule.role, 'name', FALLBACK_MESSAGE.dash) }}
          </div>
        </div>
        <div class="col-6 mt-5">
          <div class="emobg-font-weight-bold mb-1">
            Circle
          </div>
          <RouterLink
            v-if="bookingRule.circle"
            :to="{
              name: crm.companies.detail.circles.edit,
              params: {
                userCompanyUuid: bookingRule.company.uuid,
                circleUuid: bookingRule.circle.uuid,
              }
            }"
            target="_blank"
            class="emobg-link-primary emobg-body-2"
          >
            {{ bookingRule.circle.name }}
          </RouterLink>
          <div v-else>
            -
          </div>
        </div>
        <div class="col-12 mt-5">
          <div class="emobg-font-weight-bold mb-1">
            Booking Types
          </div>
          <div>
            {{ sentenceCase(join(bookingRule.bookingType, ',')) }}
          </div>
        </div>
        <div
          v-if="bookingRule.type === BOOKING_RULES_TYPES.integer"
          class="col-12 mt-5 pl-1"
        >
          <ui-text-input
            :value="value"
            name="value"
            type="number"
            label="Value*"
            @changevalue="({ detail }) => value = detail"
          />
          <div class="emobg-font-x-small emobg-color-ink-light pl-1 pt-1">
            Default: {{ bookingRule.defaultValue }}
          </div>
        </div>
        <div
          v-if="bookingRule.type === BOOKING_RULES_TYPES.boolean"
          class="col-12 mt-5 pl-0"
        >
          <div class="d-flex align-items-center ml-1">
            <ui-toggle
              :value="!!parseInt(value, 10)"
              :text="parseInt(value, 10) ? 'Yes' : 'No'"
              label="Value"
              @changevalue="({ detail }) => value = detail ? '1' : '0'"
            />
          </div>
          <div class="emobg-font-x-small emobg-color-ink-light pl-2 mt-2">
            Default: {{ bookingRule.defaultValue ? 'Yes' : 'No' }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        data-test-id="booking-rules-edit-button"
        :disabled="isDisabledSave"
        :loading="bookingRuleStatus.LOADING"
        @clickbutton="saveBookingRule"
      >
        Save
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
