import { RECORD_TYPES } from '@emobg/motion-ui';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import get from 'lodash/get';
import join from 'lodash/join';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { PermissionLink } from '@/components';
import { BOOKING_RULES_TYPES } from './constants';
export const bookingRulesTableConfig = [
  {
    header: 'Name',
    template: bookingRule => bookingRule.key,
  },
  {
    header: 'Booking type',
    template: bookingRule => join(bookingRule.bookingType, ', '),
  },
  {
    header: 'Value',
    template: bookingRule => {
      if (bookingRule.type === BOOKING_RULES_TYPES.boolean) {
        return parseInt(bookingRule.value, 10) ? 'Yes' : 'No';
      }
      return bookingRule.value || bookingRule.defaultValue;
    },
  },
  {
    header: 'Company name',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    template: 'company.name',
    props: result => {
      const companyUuid = get(result, 'company.uuid');
      return {
        classLink: 'emobg-link-primary emobg-body-2',
        href: companyUuid && companyProfileUrl(companyUuid),
        linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
        text: get(result, 'company.name', FALLBACK_MESSAGE.dash),
        target: '_blank',
      };
    },
  },
  {
    header: 'Fleet segment',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    template: 'fleetSegment.name',
    props: result => {
      const companyUuid = get(result, 'company.uuid');
      const fleetSegmentUuid = get(result, 'fleetSegment.uuid');
      return {
        classLink: 'emobg-link-primary emobg-body-2',
        to: companyUuid && fleetSegmentUuid && {
          name: crm.companies.detail.fleetSegments.edit,
          params: {
            companyUuid,
            fleetSegmentUuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
        text: get(result, 'fleetSegment.name', FALLBACK_MESSAGE.dash),
      };
    },
  },
  {
    header: 'Circle',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    template: 'circle.name',
    props: result => {
      const userCompanyUuid = get(result, 'company.uuid');
      const circleUuid = get(result, 'circle.uuid');
      return {
        classLink: 'emobg-link-primary emobg-body-2',
        to: userCompanyUuid && circleUuid && {
          name: crm.companies.detail.circles.edit,
          params: {
            userCompanyUuid,
            circleUuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
        text: get(result, 'circle.name', FALLBACK_MESSAGE.dash),
      };
    },
  },
  {
    header: 'Role',
    template: bookingRule => get(bookingRule, 'role.name', FALLBACK_MESSAGE.dash),
  },
  {
    header: 'Custom rule',
    template: bookingRule => bookingRule.customRule,
  },
  {
    header: 'Description',
    template: bookingRule => bookingRule.description || FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
  {
    header: 'Use case',
    template: bookingRule => get(bookingRule, 'bookingUseCase.alias', FALLBACK_MESSAGE.dash),
    collapsed: true,
  },
  {
    header: 'Updated',
    template: bookingRule => bookingRule.updatedAt || FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
  {
    header: 'Group',
    template: bookingRule => bookingRule.group || FALLBACK_MESSAGE.dash,
    collapsed: true,
  },
];

export const bookingRulesFilters = [
  {
    type: 'refinementList',
    props: {
      title: 'Custom rule',
      attributeName: 'customRule',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Booking type',
      attributeName: 'bookingType',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Group',
      attributeName: 'group',
      transformValue: value => value || FALLBACK_MESSAGE.noData,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Company name',
      attributeName: 'company.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fleet segment',
      attributeName: 'fleetSegment.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Circle',
      attributeName: 'circle.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Use case',
      attributeName: 'bookingUseCase.alias',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Role',
      attributeName: 'role.name',
      transformValue: sentenceCase,
    },
  },
];
