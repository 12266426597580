var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "BookingRulesView" },
    [
      _c(
        "div",
        {
          staticClass: "mb-2 d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h2", { staticClass: "d-flex align-items-center" }, [
                _vm._v(" Booking rules "),
              ]),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-1",
                  attrs: {
                    placement: _vm.PLACEMENTS.right,
                    tooltip: "Booking rules filtered by selected operator",
                  },
                },
                [
                  _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.infoFull,
                      color: _vm.GRAYSCALE.inkLight,
                      hover: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "ui-card",
        [
          _vm.bookingRulesStatus.LOADING
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading booking rules list...",
                  absolute: "",
                  "data-test-id": "loader",
                },
              })
            : _vm._e(),
          _c("FilterTableComponent", {
            staticClass: "p-0",
            staticStyle: { padding: "0 !important", border: "none !important" },
            attrs: {
              schema: _vm.bookingRulesTableConfig,
              data: _vm.rules,
              filters: _vm.bookingRulesFilters,
              "per-page": 20,
              "row-actions": _vm.bookingRulesRowActions,
              "export-name": "booking_rules",
              "data-test-id": "list",
              searchable: "",
              exportable: "",
            },
          }),
        ],
        1
      ),
      _vm.isEditBookingRuleOpen
        ? _c("BookingRulesEditModal", {
            attrs: {
              "booking-rule": _vm.currentBookingRule,
              "refresh-table": _vm.getBookingRules,
            },
            on: {
              closeModal: function ($event) {
                _vm.isEditBookingRuleOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }