<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import split from 'lodash/split';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

import { DATE_FORMAT } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent, PageView } from '@/components';

import { bookingRulesFilters, bookingRulesTableConfig } from './tableConfig';
import BookingRulesEditModal from './BookingRulesEditModal/BookingRulesEditModal';

export default {
  name: 'BookingRulesView',
  components: {
    BookingRulesEditModal,
    FilterTableComponent,
    PageView,
  },
  data() {
    return {
      isEditBookingRuleOpen: false,
      currentBookingRule: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
    ...mapState(DOMAINS_MODEL.settings.bookingRules, {
      bookingRules: state => state.data,
      bookingRulesStatus: state => state.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      activeOperatorName: state => get(state, 'operators.active.name'),
    }),
    rules() {
      return map(this.bookingRules, bookingRule => ({
        ...bookingRule,
        bookingType: split(bookingRule.bookingType, ','),
        customRule: bookingRule.valueId ? 'Yes' : 'No',
        updatedAt: bookingRule.updatedAt ? moment.utc(bookingRule.updatedAt).local().format(DATE_FORMAT.filter) : '',
      }));
    },
  },
  watch: {
    async activeOperatorUuid() {
      await this.getBookingRules();
    },
  },
  async created() {
    this.bookingRulesTableConfig = bookingRulesTableConfig;
    this.bookingRulesFilters = bookingRulesFilters;
    this.currentDate = moment().format(DATE_FORMAT.isoDate);
    this.bookingRulesRowActions = [
      {
        label: 'Edit booking rule',
        labelClass: 'emobg-font-weight-semibold',
        action: bookingRule => {
          this.currentBookingRule = bookingRule;
          this.isEditBookingRuleOpen = true;
        },
        hideAction: bookingRule => !bookingRule.valueId,
      },
    ];
    await this.getBookingRules();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.settings.bookingRules, [
      'getOperatorBookingRules',
    ]),
    ...mapMutations(DOMAINS_MODEL.settings.bookingRules, {
      setBookingRulesData: 'setData',
    }),
    async getBookingRules() {
      if (!isEmpty(this.bookingRules)) {
        this.setBookingRulesData([]);
      }
      await this.getOperatorBookingRules({ operatorUuid: this.activeOperatorUuid });
    },
  },
};
</script>
<template>
  <PageView class="BookingRulesView">
    <div class="mb-2 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h2 class="d-flex align-items-center">
          Booking rules
        </h2>
        <ui-tooltip
          :placement="PLACEMENTS.right"
          tooltip="Booking rules filtered by selected operator"
          class="ml-1"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            :color="GRAYSCALE.inkLight"
            hover
          />
        </ui-tooltip>
      </div>
    </div>
    <ui-card>
      <ui-loader
        v-if="bookingRulesStatus.LOADING"
        label="Loading booking rules list..."
        absolute
        data-test-id="loader"
      />
      <FilterTableComponent
        :schema="bookingRulesTableConfig"
        :data="rules"
        :filters="bookingRulesFilters"
        :per-page="20"
        :row-actions="bookingRulesRowActions"
        export-name="booking_rules"
        data-test-id="list"
        class="p-0"
        style="padding: 0 !important; border: none !important;"
        searchable
        exportable
      />
    </ui-card>
    <BookingRulesEditModal
      v-if="isEditBookingRuleOpen"
      :booking-rule="currentBookingRule"
      :refresh-table="getBookingRules"
      @closeModal="isEditBookingRuleOpen = false"
    />
  </PageView>
</template>
