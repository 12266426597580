var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "BookingRulesEditModal",
        attrs: {
          header: { title: `Edit ${_vm.bookingRule.key}`, isClosable: true },
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.settings.bookingRules,
                    scope: _vm.BOOKING_RULES_SCOPES.editForm,
                    action: "editting booking rule",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "emobg-font-weight-bold mb-1" }, [
                  _vm._v(" Description "),
                ]),
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.bookingRule.description) + " "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6 mt-5" }, [
                    _c("div", { staticClass: "emobg-font-weight-bold mb-1" }, [
                      _vm._v(" Company Name "),
                    ]),
                    _vm.bookingRule.company
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "emobg-link-primary emobg-body-2",
                              attrs: {
                                href: _vm.companyProfileUrl(
                                  _vm.bookingRule.company.uuid
                                ),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.bookingRule.company.name) + " "
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [_vm._v(" - ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-6 mt-5" },
                    [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-1" },
                        [_vm._v(" Fleet Segment ")]
                      ),
                      _vm.bookingRule.fleetSegment
                        ? _c(
                            "RouterLink",
                            {
                              staticClass: "emobg-link-primary emobg-body-2",
                              attrs: {
                                to: {
                                  name: _vm.crm.companies.detail.fleetSegments
                                    .edit,
                                  params: {
                                    companyUuid: _vm.bookingRule.company.uuid,
                                    fleetSegmentUuid:
                                      _vm.bookingRule.fleetSegment.uuid,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.bookingRule.fleetSegment.name) +
                                  " "
                              ),
                            ]
                          )
                        : _c("div", [_vm._v(" - ")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-6 mt-5" }, [
                    _c("div", { staticClass: "emobg-font-weight-bold mb-1" }, [
                      _vm._v(" Role "),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.get(
                              _vm.bookingRule.role,
                              "name",
                              _vm.FALLBACK_MESSAGE.dash
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-6 mt-5" },
                    [
                      _c(
                        "div",
                        { staticClass: "emobg-font-weight-bold mb-1" },
                        [_vm._v(" Circle ")]
                      ),
                      _vm.bookingRule.circle
                        ? _c(
                            "RouterLink",
                            {
                              staticClass: "emobg-link-primary emobg-body-2",
                              attrs: {
                                to: {
                                  name: _vm.crm.companies.detail.circles.edit,
                                  params: {
                                    userCompanyUuid:
                                      _vm.bookingRule.company.uuid,
                                    circleUuid: _vm.bookingRule.circle.uuid,
                                  },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.bookingRule.circle.name) + " "
                              ),
                            ]
                          )
                        : _c("div", [_vm._v(" - ")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 mt-5" }, [
                    _c("div", { staticClass: "emobg-font-weight-bold mb-1" }, [
                      _vm._v(" Booking Types "),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.sentenceCase(
                              _vm.join(_vm.bookingRule.bookingType, ",")
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.bookingRule.type === _vm.BOOKING_RULES_TYPES.integer
                    ? _c(
                        "div",
                        { staticClass: "col-12 mt-5 pl-1" },
                        [
                          _c("ui-text-input", {
                            attrs: {
                              value: _vm.value,
                              name: "value",
                              type: "number",
                              label: "Value*",
                            },
                            on: {
                              changevalue: ({ detail }) => (_vm.value = detail),
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "emobg-font-x-small emobg-color-ink-light pl-1 pt-1",
                            },
                            [
                              _vm._v(
                                " Default: " +
                                  _vm._s(_vm.bookingRule.defaultValue) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.bookingRule.type === _vm.BOOKING_RULES_TYPES.boolean
                    ? _c("div", { staticClass: "col-12 mt-5 pl-0" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center ml-1" },
                          [
                            _c("ui-toggle", {
                              attrs: {
                                value: !!parseInt(_vm.value, 10),
                                text: parseInt(_vm.value, 10) ? "Yes" : "No",
                                label: "Value",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.value = detail ? "1" : "0"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "emobg-font-x-small emobg-color-ink-light pl-2 mt-2",
                          },
                          [
                            _vm._v(
                              " Default: " +
                                _vm._s(
                                  _vm.bookingRule.defaultValue ? "Yes" : "No"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      "data-test-id": "booking-rules-edit-button",
                      disabled: _vm.isDisabledSave,
                      loading: _vm.bookingRuleStatus.LOADING,
                    },
                    on: { clickbutton: _vm.saveBookingRule },
                  },
                  [_vm._v(" Save ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }